<div class="grid-container">
  <div class="value-component">
    <span [ngSwitch]="resourcePropertyDefinition.objectType">
      <app-text-value-as-string
        #createVal
        *ngSwitchCase="'ReadTextValueAsString'"
        [mode]="mode"
        [guiElement]="textValueGuiEle"
        [textArea]="textArea"></app-text-value-as-string>
      <app-text-value-as-html #createVal *ngSwitchCase="'ReadTextValueAsHtml'" [mode]="mode"></app-text-value-as-html>
      <app-text-value-as-xml #createVal *ngSwitchCase="'ReadTextValueAsXml'" [mode]="mode"></app-text-value-as-xml>
      <app-int-value #createVal *ngSwitchCase="constants.IntValue" [mode]="mode"></app-int-value>
      <app-boolean-value #createVal *ngSwitchCase="constants.BooleanValue" [mode]="mode"></app-boolean-value>
      <app-uri-value #createVal *ngSwitchCase="constants.UriValue" [mode]="mode"></app-uri-value>
      <app-decimal-value #createVal *ngSwitchCase="constants.DecimalValue" [mode]="mode"></app-decimal-value>
      <app-color-value #createVal *ngSwitchCase="constants.ColorValue" [mode]="mode"></app-color-value>
      <app-interval-value #createVal *ngSwitchCase="constants.IntervalValue" [mode]="mode"></app-interval-value>
      <app-time-value #createVal *ngSwitchCase="constants.TimeValue" [mode]="mode"></app-time-value>
      <app-geoname-value #createVal *ngSwitchCase="constants.GeonameValue" [mode]="mode"></app-geoname-value>
      <app-link-value
        #createVal
        *ngSwitchCase="constants.LinkValue"
        [mode]="mode"
        [parentResource]="parentResource"
        [propIri]="resourcePropertyDefinition.id"
        [currentOntoIri]="ontoIri"></app-link-value>
      <app-date-value #createVal *ngSwitchCase="constants.DateValue" [mode]="mode"></app-date-value>
      <app-list-value
        #createVal
        *ngSwitchCase="constants.ListValue"
        [mode]="mode"
        [propertyDef]="resourcePropertyDefinition"></app-list-value>
    </span>
  </div>
  <div class="crud-buttons">
    <button
      mat-icon-button
      *ngIf="createModeActive"
      class="value-action save"
      title="save"
      [disabled]="!createValueComponent.form.valid"
      (click)="saveAddValue()">
      <mat-icon>save</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="createModeActive"
      class="value-action cancel"
      title="cancel"
      (click)="cancelAddValue()">
      <mat-icon>cancel</mat-icon>
    </button>
    <dasch-swiss-app-progress-indicator
      *ngIf="submittingValue"
      [status]="progressIndicatorStatus"></dasch-swiss-app-progress-indicator>
  </div>
</div>
