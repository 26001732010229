<!-- openseadragon (osd) viewer -->
<div class="osd-container" [class.drawing]="regionDrawMode">
  <!-- in case of an error -->
  <app-status
    [status]="404"
    [url]="images[0].fileValue.fileUrl"
    [representation]="'still-image'"
    *ngIf="failedToLoad"></app-status>

  <!-- navigation in case of compound object: previous and next page -->
  <div class="navigation vertical previous" *ngIf="compoundNavigation">
    <button
      mat-button
      class="full-size"
      [disabled]="compoundNavigation.page <= 1"
      (click)="openPage(compoundNavigation.page - 1)">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>
  <div class="navigation vertical next" *ngIf="compoundNavigation">
    <button
      mat-button
      class="full-size"
      [disabled]="
                compoundNavigation.page >= compoundNavigation.totalPages
            "
      (click)="openPage(compoundNavigation.page + 1)">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>

<div class="toolbar bottom">
  <!-- navigation -->
  <div class="navigation horizontal" *ngIf="compoundNavigation">
    <!-- TODO: The 'tickInterval' property no longer exists -->
    <mat-slider
      [color]="'primary'"
      [disabled]="compoundNavigation.totalPages < 2"
      [max]="compoundNavigation.totalPages"
      [min]="1"
      [step]="1"
      showTickMarks
      discrete
      #ngSlider
      ><input
        matSliderThumb
        [(ngModel)]="compoundNavigation.page"
        (change)="
                    openPage(
                        {
                            source: ngSliderThumb,
                            parent: ngSlider,
                            value: ngSliderThumb.value
                        }.value
                    )
                "
        #ngSliderThumb="matSliderThumb" />
    </mat-slider>
  </div>
  <!-- toolbar -->
  <div class="action horizontal">
    <!-- vertical more button with menu to open and copy iiif url -->
    <button mat-icon-button [matMenuTriggerFor]="more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #more="matMenu" class="representation-menu">
      <button class="menu-content" mat-menu-item (click)="openImageInNewTab(images[0].fileValue.fileUrl)">
        Open IIIF URL in new tab
      </button>
      <button
        class="menu-content"
        mat-menu-item
        [cdkCopyToClipboard]="images[0].fileValue.fileUrl"
        (click)="openSnackBar('IIIF URL copied to clipboard!')">
        Copy IIIF URL to clipboard
      </button>
      <button class="menu-content" mat-menu-item (click)="download(images[0].fileValue.fileUrl)">Download image</button>
      <button class="menu-content" mat-menu-item [disabled]="!adminPermissions" (click)="openReplaceFileDialog()">
        Replace file
      </button>
    </mat-menu>
    <!-- empty placeholder to simulate two buttons; this helps to have the zoom buttons centered with fill-remaining-space setup -->
    <span class="empty-space"></span>
    <span class="empty-space"></span>

    <span class="fill-remaining-space"></span>

    <!-- navigation in case of compound object: first page and previous page -->
    <span class="compound-nav" *ngIf="compoundNavigation">
      <button mat-icon-button [disabled]="compoundNavigation.page <= 1" matTooltip="First page" (click)="openPage(1)">
        <mat-icon>first_page</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="compoundNavigation.page <= 1"
        matTooltip="Previous page"
        (click)="openPage(compoundNavigation.page - 1)">
        <mat-icon>navigate_before</mat-icon>
      </button>
    </span>

    <!-- zoom buttons: zoom out, home/reset, zoom in -->
    <span>
      <button mat-icon-button id="DSP_OSD_ZOOM_OUT" matTooltip="Zoom out" [disabled]="failedToLoad">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_HOME" matTooltip="Reset zoom" [disabled]="failedToLoad">
        <mat-icon>adjust</mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_ZOOM_IN" matTooltip="Zoom in" [disabled]="failedToLoad">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </span>

    <!-- navigation in case of compound object: next page and last page -->
    <span class="compound-nav" *ngIf="compoundNavigation">
      <button
        mat-icon-button
        [disabled]="
                    compoundNavigation.page >= compoundNavigation.totalPages
                "
        matTooltip="Next page"
        (click)="openPage(compoundNavigation.page + 1)">
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button
        mat-icon-button
        [disabled]="
                    compoundNavigation.page === compoundNavigation.totalPages
                "
        matTooltip="Last page"
        (click)="openPage(compoundNavigation.totalPages)">
        <mat-icon>last_page</mat-icon>
      </button>
    </span>

    <span class="fill-remaining-space"></span>

    <!-- action buttons: create annotation/region, fullscreen -->
    <span>
      <button
        mat-icon-button
        id="DSP_OSD_DRAW_REGION"
        matTooltip="Draw Region"
        [disabled]="failedToLoad || !adminPermissions"
        (click)="drawButtonClicked()"
        [class.active]="regionDrawMode">
        <mat-icon svgIcon="draw_region_icon"></mat-icon>
      </button>
      <button mat-icon-button id="DSP_OSD_FULL_PAGE" matTooltip="Open in fullscreen" [disabled]="failedToLoad">
        <mat-icon>fullscreen</mat-icon>
      </button>
    </span>
  </div>
</div>
