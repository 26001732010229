<dasch-swiss-app-progress-indicator *ngIf="(isProjectsLoading$ | async) === true"></dasch-swiss-app-progress-indicator>
<div *ngIf="(isProjectsLoading$ | async) === false" class="app-projects">
  <!-- <mat-divider></mat-divider> -->
  <app-projects-list [list]="activeProjects$ | async" [status]="true" (refreshParent)="refresh()" [createNew]="true">
  </app-projects-list>
  <!-- already deactivated projects: disable the menu -->
  <app-projects-list
    [list]="inactiveProjects$ | async"
    [status]="false"
    (refreshParent)="refresh()"></app-projects-list>
</div>
