<form [formGroup]="propertiesForm" (ngSubmit)="onSubmit()">
  <!-- upload file -->
  <app-upload
    *ngIf="hasFileValue"
    [parentForm]="propertiesForm"
    [representation]="hasFileValue"
    (fileInfo)="setFileValue($event)">
  </app-upload>

  <app-select-properties
    #selectProps
    [ontologyInfo]="ontologyInfo"
    [selectedResourceClass]="resourceClass"
    [properties]="properties"
    [parentForm]="propertiesForm"
    [currentOntoIri]="currentOntoIri"
    class="select-properties">
  </app-select-properties>
  <div class="form-panel form-action">
    <span>
      <button mat-button type="button" (click)="onCancel()">{{ 'appLabels.form.action.cancel' | translate }}</button>
    </span>
    <span class="fill-remaining-space"></span>
    <span>
      <button mat-raised-button type="submit" color="primary" class="form-submit" [disabled]="!propertiesForm.valid">
        {{ 'appLabels.form.action.submit' | translate }}
      </button>
    </span>
  </div>
</form>
