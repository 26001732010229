<!-- this is the progress indicator for forms -->
<div class="app-progress-indicator submit" *ngIf="status !== undefined; else isLoading">
  <!-- spinner while on load / on submit -->
  <div class="on-submit" *ngIf="status === 0">
    <div class="spinner" [style.border-top-color]="color" [style.border-left-color]="color"></div>
  </div>

  <div>
    <!-- bullet point before submit -->
    <mat-icon *ngIf="status === -1" class="before-submit">keyboard_arrow_right</mat-icon>
    <!-- icon 'check' when done -->
    <mat-icon *ngIf="status === 1" class="after-submit" [style.color]="color">done</mat-icon>
    <!-- in case of an error -->
    <mat-icon *ngIf="status === 400" class="submit-error">not_interested</mat-icon>
  </div>
</div>

<!-- default case: is loading -->
<ng-template #isLoading>
  <div class="app-progress-indicator default">
    <svg
      class="loader"
      [attr.width]="widthAndHeight"
      [attr.height]="widthAndHeight"
      viewBox="5 20 200 170"
      preserveAspectRatio="xMidYMid meet"
      version="1.1">
      <g [attr.stroke]="color" stroke-width="11" fill="none">
        <path
          d="m 107.19141,27.064453 c 19.40811,0.72674 40.31867,-1.456974 57.69737,9.089791 21.86918,11.995467 35.15372,37.302817
                32.81003,62.109081 -1.44162,27.181075 -22.18624,51.396565 -48.36746,58.105135 -15.52706,3.91583 -31.7106,1.96603
                -47.54641,2.56104 -12.037336,-0.47103 -24.435002,1.15224 -36.165837,-2.14642 C 47.465101,151.64804 35.68418,132.01794
                38.846261,113.58197 41.044909,94.96699 58.432119,80.249806 77.041016,80.271484 c 17.227977,-0.187132 34.488494,-0.150034
                51.722344,-0.05393 7.48754,-0.636519 15.65102,4.272294 15.16633,12.536357 1.1481,10.695294 -10.49745,14.507204
                -19.15,13.629524 -16.50044,0.8151 -33.109787,-1.33333 -49.544308,0.59021 -12.16565,1.08773 -13.682733,21.19581
                -2.229523,24.54784 12.153952,2.31023 24.624424,0.72973 36.926141,1.31316 15.925,-0.0149 33.92093,1.88492 46.94691,-9.26237
                15.92807,-12.50054 19.37984,-37.763607 6.74173,-53.762153 C 155.67021,58.639225 141.87544,52.91852 128.35558,53.71244
                106.95264,53.816817 85.345205,51.919886 64.110062,55.225243 34.76089,60.210981 11.510586,87.802975 11.20046,117.51937
                c -1.2978833,28.30459 18.033663,55.93384 45.060676,64.41866 14.698473,5.04904 30.459737,3.11351 45.695894,3.66939
                -17.420023,-0.6798 -35.760774,1.3379 -52.028837,-6.35029 C 24.919537,168.2006 8.6123975,140.39068 11.757798,113.13809
                13.979432,83.18657 39.693549,57.195801 69.617745,54.611051 c 20.938633,-1.884763 42.015395,-0.718245 63.009365,-0.538664
                21.98719,-0.34384 41.15358,21.050135 38.24546,42.865168 -1.45796,19.577435 -19.49062,35.534895 -38.98479,35.409455
                -16.79472,0.69111 -33.615302,-0.015 -50.421429,0.12436 -8.946772,1.23307 -18.212835,-5.83812 -15.724838,-15.34398
                0.42247,-8.60164 10.002724,-10.99166 17.091887,-10.64743 17.28209,-0.51175 34.70938,1.55524 51.88813,-0.85383
                12.33313,-1.76868 12.67077,-22.428759 0.57299,-24.843656 C 122.63021,78.92436 109.75845,80.292039 96.99706,79.912109
                82.206525,79.982527 65.775345,78.124805 53.519531,88.164062 35.12557,101.2767 33.081035,130.5764 48.717405,146.60724
                c 8.968191,9.78898 22.647018,13.18722 35.509867,12.46014 18.713198,0.019 37.488438,0.58386 56.161608,-0.70035
                28.73172,-3.16296 53.8499,-27.19104 57.15814,-56.10834 3.4273,-23.2831 -6.49873,-47.955352 -25.6328,-61.833968
                -13.91005,-10.94423 -32.05671,-13.81719 -49.28858,-13.194089 -5.14452,-0.0723 -10.28903,-0.166142 -15.43423,-0.16618 z" />
      </g>
    </svg>
  </div>
</ng-template>
