<span *ngIf="mode === 'read'; else showForm" class="read-mode-view">
  <span class="rm-value">
    <a class="link" target="_blank" rel="noopener" href="https://www.geonames.org/{{ valueFormControl.value?.id }}"
      >{{ ($geonameLabel | async)?.displayName }}</a
    >
  </span>
  <span class="rm-comment" *ngIf="shouldShowComment"> {{ commentFormControl.value }} </span>
</span>
<ng-template #showForm>
  <span [formGroup]="form" class="form-fields-container">
    <span *ngIf="mode === 'update'" class="current-value"
      >Current value: {{ ($geonameLabel | async)?.displayName }}
    </span>
    <mat-form-field class="large-field child-value-component" floatLabel="never">
      <input
        matInput
        [formControlName]="'value'"
        class="value"
        type="text"
        placeholder="Geoname value"
        aria-label="geoname"
        [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPlaceInSearch">
        <mat-option *ngFor="let place of places" [value]="place"> {{ place?.displayName }} </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="
                    valueFormControl.hasError('valueNotChanged') &&
                    (valueFormControl.touched || valueFormControl.dirty)
                ">
        <span class="custom-error-message">New value must be different than the current value.</span>
      </mat-error>
      <mat-error *ngIf="valueFormControl.hasError('required')  && mode !== 'update'">
        A GeoName value is <strong>required</strong> {{mode}}.
      </mat-error>
      <mat-error *ngIf="valueFormControl.hasError('required')  && mode === 'update'">
        Empty values are not supported when updating a value. Consider using the delete button instead.
      </mat-error>
      <mat-error *ngIf="valueFormControl.hasError('duplicateValue')">
        <span class="custom-error-message"
          >This value already exists for this property. Duplicate values are not allowed.</span
        >
      </mat-error>
    </mat-form-field>
    <app-comment-form
      *ngIf="!commentDisabled && valueFormControl && commentFormControl"
      [valueFormControlHasError]="hasError()"
      [(commentFormControl)]="commentFormControl"
      [valueFormControlValue]="valueFormControl.value"></app-comment-form>
  </span>
</ng-template>
