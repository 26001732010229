<div [ngSwitch]="data.mode" class="dialog-content">
  <!-- all about USER -->
  <!-- Create new user profile -->
  <div *ngSwitchCase="'createUser'">
    <app-dialog-header
      [title]="'appLabels.form.user.title.profile' | translate"
      [subtitle]="'appLabels.form.user.title.new' | translate">
    </app-dialog-header>
    <app-user-form [name]="data.name" [projectUuid]="data.project" (closeDialog)="dialogRef.close()"> </app-user-form>
  </div>

  <!-- Modify user data -->
  <div *ngSwitchCase="'editUser'">
    <app-dialog-header [title]="data.user.username" [subtitle]="'appLabels.form.user.title.edit' | translate">
    </app-dialog-header>
    <app-user-form [user]="data.user" (closeDialog)="dialogRef.close()"></app-user-form>
  </div>

  <!-- Update password -->
  <div *ngSwitchCase="'editPassword'">
    <app-dialog-header [title]="data.user.username" [subtitle]="'appLabels.form.user.title.password' | translate">
    </app-dialog-header>
    <app-password-form [user]="data.user" (closeDialog)="dialogRef.close()"></app-password-form>
  </div>

  <!-- Manage user project membership -->
  <div *ngSwitchCase="'manageProjectMembership'">
    <app-dialog-header [title]="data.user.username" [subtitle]="'Manage project membership'"> </app-dialog-header>
    <app-membership [user]="data.user" (closeDialog)="dialogRef.close()"></app-membership>
    <mat-dialog-actions>
      <span class="fill-remaining-space"></span>
      <button mat-button color="primary" class="cancel-button center" [mat-dialog-close]="false">Close</button>
    </mat-dialog-actions>
  </div>

  <!-- Ontology editor: create new ontology -->
  <div *ngSwitchCase="'createOntology'">
    <app-dialog-header [title]="data.title" [subtitle]="'Create new'"></app-dialog-header>
    <app-ontology-form [projectUuid]="data.project" (closeDialog)="dialogRef.close($event)"></app-ontology-form>
  </div>

  <div *ngSwitchCase="'editOntology'">
    <app-dialog-header [title]="data.title" [subtitle]="'Customize data model'" (closed)="dialogRef.close()">
    </app-dialog-header>
    <app-ontology-form
      [iri]="data.id"
      [projectUuid]="data.project"
      (closeDialog)="dialogRef.close($event)"></app-ontology-form>
  </div>

  <div *ngSwitchCase="'updateCardinality'">
    <app-property-form
      [propertyInfo]="data.propInfo"
      [resClassIri]="data.parentIri"
      [guiOrder]="data.position"
      [classProperties]="data.classProperties"
      [changeCardinalities]="true"
      [targetGuiCardinality]="data.targetCardinality"
      [currentCardinality]="data.currentCardinality"
      (closeDialog)="dialogRef.close()">
    </app-property-form>
  </div>

  <div *ngSwitchCase="'createProperty'">
    <app-dialog-header [title]="data.title" [subtitle]="data.subtitle"> </app-dialog-header>
    <app-property-form
      [propertyInfo]="data.propInfo"
      [resClassIri]="data.parentIri"
      [guiOrder]="data.position"
      (closeDialog)="dialogRef.close()">
    </app-property-form>
  </div>

  <div *ngSwitchCase="'editProperty'">
    <app-dialog-header [title]="data.title" [subtitle]="data.subtitle"> </app-dialog-header>
    <app-property-form
      [propertyInfo]="data.propInfo"
      [resClassIri]="data.parentIri"
      [guiOrder]="data.position"
      (closeDialog)="dialogRef.close($event)">
    </app-property-form>
  </div>

  <!-- all about RESOURCE -->
  <div *ngSwitchCase="'editResource'">
    <app-dialog-header [title]="data.title" [subtitle]="'Edit resource\'s label'"> </app-dialog-header>
    <mat-dialog-content class="form-content">
      <mat-form-field class="large-field">
        <input matInput placeholder="Label" [value]="data.title" (keyup)="onKey($event)" />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        color="primary"
        mat-dialog-close
        class="cancel-button center"
        [mat-dialog-close]="{ confirmed: false, comment: comment }">
        Cancel
      </button>
      <span class="fill-remaining-space"></span>
      <button
        mat-button
        mat-raised-button
        [color]="'primary'"
        class="confirm-button center"
        [mat-dialog-close]="{ confirmed: true, comment: comment }"
        [disabled]="!comment">
        Update
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'deleteResource'">
    <app-dialog-header [title]="data.title" [subtitle]="'Delete resource instance'"></app-dialog-header>
    <mat-dialog-content class="form-content">
      <mat-form-field class="large-field">
        <mat-label>Comment why resource is being deleted</mat-label>
        <textarea
          matInput
          class="deletion-comment"
          type="text"
          (keyup)="onKey($event)"
          [placeholder]="'Ex. Resource was created by mistake...'"></textarea>
      </mat-form-field>
      Do you want to delete this resource?
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        color="primary"
        mat-dialog-close
        class="cancel-button center"
        [mat-dialog-close]="{ confirmed: false, comment: comment }">
        No, keep it
      </button>
      <span class="fill-remaining-space"></span>
      <button
        mat-button
        mat-raised-button
        [color]="'warn'"
        class="confirm-button center"
        [mat-dialog-close]="{ confirmed: true, comment: comment }">
        Yes, delete
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'eraseResource'">
    <app-dialog-header [title]="data.title" [subtitle]="'Delete resource'"></app-dialog-header>
    Do you want to erase this resource forever?<br /><br />WARNING: This action cannot be undone, so use it with care.
    <mat-dialog-actions>
      <button
        mat-button
        color="primary"
        mat-dialog-close
        class="cancel-button center"
        [mat-dialog-close]="{ confirmed: false, comment: comment }">
        No, keep it
      </button>
      <span class="fill-remaining-space"></span>
      <button
        mat-button
        mat-raised-button
        [color]="'warn'"
        class="confirm-button center"
        [mat-dialog-close]="{ confirmed: true, comment: comment }">
        Yes, erase forever
      </button>
    </mat-dialog-actions>
  </div>

  <div *ngSwitchCase="'addRegion'">
    <app-dialog-header [title]="data.title" [subtitle]="data.subtitle"></app-dialog-header>
    <app-add-region-form [resourceIri]="data.id"></app-add-region-form>
  </div>

  <div *ngSwitchCase="'replaceFile'">
    <app-dialog-header [title]="data.title" [subtitle]="data.subtitle"></app-dialog-header>
    <mat-dialog-content class="form-content">
      <app-replace-file-form
        [representation]="data.representation"
        [propId]="data.id"
        (closeDialog)="dialogRef.close($event)"></app-replace-file-form>
    </mat-dialog-content>
  </div>
  <div *ngSwitchCase="'linkResources'">
    <app-dialog-header [title]="data.title" [subtitle]="'Link resources'"></app-dialog-header>
    <mat-dialog-content>
      <app-resource-link-form
        [resources]="data.selectedResources"
        (closeDialog)="dialogRef.close($event)"></app-resource-link-form>
    </mat-dialog-content>
  </div>

  <div *ngSwitchCase="'createLinkResource'">
    <app-dialog-header [title]="data.title" [subtitle]="'Create a new linked resource'"></app-dialog-header>
    <mat-dialog-content>
      <app-create-link-resource
        [parentResource]="data.parentResource"
        [propDef]="data.id"
        [resourceClassDef]="data.resourceClassDefinition"
        [currentOntoIri]="data.ontoIri"
        (closeDialog)="dialogRef.close($event)"></app-create-link-resource>
    </mat-dialog-content>
  </div>

  <!-- general error message -->
  <div *ngSwitchCase="'error'">
    <app-status [status]="data.id" [comment]="data.comment"></app-status>
  </div>

  <!-- Unknown method (or not yet defined) -->
  <!-- Default dialog content component -->
  <div *ngSwitchDefault>
    <app-dialog-header [title]="'Not yet implemented'" [subtitle]="'ERROR 400'" (closed)="dialogRef.close()">
    </app-dialog-header>
    <p class="todo" [innerHtml]="notYetImplemented"></p>
    <mat-dialog-actions>
      <span class="fill-remaining-space"></span>
      <button mat-button mat-dialog-close class="cancel-button center" [mat-dialog-close]="false">OK</button>
      <span class="fill-remaining-space"></span>
    </mat-dialog-actions>
  </div>
</div>
