<span [ngSwitch]="property.objectType">
  <app-text-value-as-string
    #createVal
    *ngSwitchCase="'ReadTextValueAsString'"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"
    [textArea]="textArea"></app-text-value-as-string>
  <app-text-value-as-xml
    #createVal
    *ngSwitchCase="'ReadTextValueAsXml'"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-text-value-as-xml>
  <app-int-value
    #createVal
    *ngSwitchCase="constants.IntValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-int-value>
  <app-boolean-value
    #createVal
    *ngSwitchCase="constants.BooleanValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"
    [moreSpace]="true"
    [newResource]="true"></app-boolean-value>
  <app-uri-value
    #createVal
    *ngSwitchCase="constants.UriValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-uri-value>
  <app-decimal-value
    #createVal
    *ngSwitchCase="constants.DecimalValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-decimal-value>
  <app-color-value
    #createVal
    *ngSwitchCase="constants.ColorValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-color-value>
  <app-interval-value
    #createVal
    *ngSwitchCase="constants.IntervalValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-interval-value>
  <app-time-value
    #createVal
    *ngSwitchCase="constants.TimeValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-time-value>
  <app-geoname-value
    #createVal
    *ngSwitchCase="constants.GeonameValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-geoname-value>
  <app-link-value
    #createVal
    *ngSwitchCase="constants.LinkValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"
    [parentResource]="parentResource"
    [propIri]="property.id"
    [currentOntoIri]="currentOntoIri"></app-link-value>
  <app-date-value
    #createVal
    *ngSwitchCase="constants.DateValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"></app-date-value>
  <app-list-value
    #createVal
    *ngSwitchCase="constants.ListValue"
    [mode]="mode"
    [valueRequiredValidator]="isRequiredProp"
    [parentForm]="parentForm"
    [formName]="formName"
    [propertyDef]="property"></app-list-value>
  <span *ngSwitchDefault>
    <p>
      Cannot match any value component for
      <strong>{{ property.objectType }}</strong>
    </p>
  </span>
</span>
