<div *ngIf="isSysAdmin$ | async; else notAllowed">
  <nav
    mat-tab-nav-bar
    mat-align-tabs="center"
    class="navigation tab-bar margin-from-top"
    animationDuration="0ms"
    [tabPanel]="tabPanel">
    <a
      mat-tab-link
      class="nav-link"
      *ngFor="let link of navigation; let first = first"
      [routerLink]="link.route"
      routerLinkActive="active-tab"
      #rla="routerLinkActive"
      [active]="rla.isActive">
      <mat-icon class="tab-icon">{{ link.icon }}</mat-icon>
      {{ link.label }}
    </a>
    <!-- mobile version -->
    <a
      mat-tab-link
      class="nav-link-mobile"
      *ngFor="let link of navigation; let first = first"
      [routerLink]="link.route"
      routerLinkActive="active-tab"
      #rla="routerLinkActive"
      [active]="rla.isActive">
      {{ link.shortLabel }}
    </a>
  </nav>

  <mat-tab-nav-panel class="content large middle" #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>

<ng-template #notAllowed>
  <div class="content large middle">
    <app-status [status]="403"></app-status>
  </div>
</ng-template>
