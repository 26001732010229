<div *ngIf="list && !loading" class="app-projects-list">
  <!-- header toolbar -->
  <div class="app-toolbar" *ngIf="list.length > 0">
    <div class="app-toolbar-row">
      <h3 class="mat-body subtitle">
        <span *ngIf="status">Active</span>
        <span *ngIf="!status">Deactivated</span>
      </h3>
      <span class="fill-remaining-space"></span>
      <span class="app-toolbar-action"> </span>
    </div>
    <div class="app-toolbar-row">
      <span class="app-toolbar-action button left">
        <!-- sort button if more than one item in the list -->
        <app-sort-button
          *ngIf="list.length"
          [icon]="'sort_by_alpha'"
          [sortProps]="sortProps"
          [activeKey]="sortBy"
          (sortKeyChange)="sortList($event)">
        </app-sort-button>
      </span>
      <h2 class="mat-headline-6">{{ list.length | i18nPlural : itemPluralMapping['project'] }}</h2>
      <span class="fill-remaining-space"></span>
      <span class="app-toolbar-action button right" *ngIf="status && createNew && (isSysAdmin$ | async) === true">
        <button mat-flat-button [color]="'primary'" (click)="createNewProject()">Create new</button>
      </span>
    </div>
  </div>

  <!-- content: list -->
  <table class="table more-space-bottom" [class.deactivated]="!status">
    <tr class="table-entry" *ngFor="let project of list; let last = last" [class.no-border]="last">
      <!-- logo -->
      <td class="avatar-cell">
        <mat-icon mat-list-icon>
          <span *ngIf="status">assignment</span>
          <span *ngIf="!status">lock</span>
        </mat-icon>
      </td>
      <td class="table-info">
        <!-- Longname hidden on phone devices -->
        <h5 class="mat-subtitle-1 link info-longname" (click)="openProjectPage(project.id)">{{ project.longname }}</h5>
        <p class="mat-subtitle-2 link" (click)="openProjectPage(project.id)">
          {{ project.shortcode }} | {{ project.shortname }}
        </p>
      </td>
      <td class="table-admin-chip">
        <mat-chip-listbox *ngIf="userIsProjectAdmin$(project.id) | async">
          <mat-chip class="admin-chip">Project Admin</mat-chip>
        </mat-chip-listbox>
      </td>
      <!-- action: menu with edit, remove, etc. -->
      <td class="table-action">
        <button
          mat-icon-button
          [matMenuTriggerFor]="projectMenu"
          [disabled]="(userHasPermission$(project.id) | async) === false || doNotDelete.includes(project.id)">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #projectMenu="matMenu" xPosition="before" class="menu">
          <button mat-menu-item *ngIf="status" (click)="editProject(project.id)">Edit project information</button>
          <button mat-menu-item *ngIf="status" (click)="askToDeactivateProject(project.shortname,project.id)">
            Deactivate project
          </button>
          <button mat-menu-item *ngIf="!status" (click)="askToActivateProject(project.shortname,project.id)">
            Reactivate project
          </button>
        </mat-menu>
      </td>
    </tr>
  </table>
</div>
