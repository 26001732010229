<div class="property-info">
  <div class="upper-prop-container">
    <div mat-line class="title">
      <span
        class="icon"
        *ngIf="propType"
        [matTooltip]="propType?.group + ': ' + propType?.label + ' (' + propDef.id.split('#')[1] + ')'"
        matTooltipPosition="above">
        <mat-icon class="type">{{propType?.icon}}</mat-icon>
      </span>
      <span
        class="label"
        [matTooltip]="propDef.comment"
        matTooltipPosition="above"
        matTooltipClass="multi-line-tooltip">
        <span>{{propDef.label ? propDef.label : propDef.id}}</span>
      </span>
      <!-- property is linked to a class or to a list: display connected class or list -->
      <span
        class="attribute additional-info"
        *ngIf="propAttribute"
        [matTooltip]="propAttributeComment"
        matTooltipPosition="above"
        [innerHTML]="'&rarr;&nbsp;' + propAttribute">
      </span>
    </div>
    <div class="edit-menu">
      <span class="menu-icon">
        <mat-icon [matMenuTriggerFor]="classInfoMenu" class="menu-icon-button" (click)="canBeRemovedFromClass()"
          >more_vert</mat-icon
        >
      </span>
      <mat-menu #classInfoMenu="matMenu">
        <div class="remove-menu-wrapper">
          <button
            mat-menu-item
            [disabled]="!propCanBeRemovedFromClass"
            (click)="removePropertyFromClass.emit({iri: propDef.id, label: propDef.label})">
            <mat-icon>link_off</mat-icon>
            <ngx-skeleton-loader
              *ngIf="propCanBeRemovedFromClass === undefined || propCanBeRemovedFromClass === null"
              count="1"
              appearance="line"
              class="skeleton"
              [theme]="{
                        'margin-bottom': 0,
                        'vertical-align': 'middle',
                        'min-width' : '16em'
                    }">
            </ngx-skeleton-loader>
            <span *ngIf="propCanBeRemovedFromClass !== undefined">remove property from class</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
  <div mat-line class="lower-prop-container">
    <span
      [matTooltip]="'id: ' + propDef.id"
      matTooltipPosition="above"
      matTooltipClass="wide-tooltip"
      class="mat-caption">
      {{propDef.id | split: '#':1}}
    </span>
    <span class="fill-remaining-space center"></span>
    <span class="cardinality-toggles">
      <mat-slide-toggle
        data-name="multiple"
        [checked]="propInfo.multiple"
        [disabled]="!userCanEdit"
        (toggleChange)="changeCardinalities.emit({ prop: propCard, propType: propType, targetCardinality: {key: 'multiple', value: !propInfo.multiple} })">
      </mat-slide-toggle>
      <span class="toggle-label">multiple</span>
      <mat-slide-toggle
        data-name="required"
        [checked]="propInfo.required"
        [disabled]="!userCanEdit"
        (toggleChange)="changeCardinalities.emit({prop: propCard, propType: propType, targetCardinality: {key: 'required', value: !propInfo.required} })">
      </mat-slide-toggle>
      <span class="toggle-label">required</span>
    </span>
  </div>
</div>
