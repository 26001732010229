<span *ngIf="mode === 'read'; else showForm" class="read-mode-view">
  <span class="rm-value">
    <mat-icon [ngClass]="valueFormControl.value ? 'toggle-icon-on': 'toggle-icon-off'"
      >{{ valueFormControl.value? 'toggle_on' : 'toggle_off' }}</mat-icon
    >
  </span>
  <span class="rm-comment" *ngIf="shouldShowComment">{{ commentFormControl.value }}</span>
</span>
<ng-template #showForm>
  <span *ngIf="mode === 'create'">
    <span *ngIf="boolValIsUnset">
      <button
        mat-icon-button
        type="button"
        class="value-action create"
        title="Add a new value"
        (click)="boolValIsUnset = false">
        <mat-icon>add_box</mat-icon>
      </button>
    </span>
  </span>
  <span [formGroup]="form" *ngIf="!boolValIsUnset">
    <span class="toggle-container">
      <mat-slide-toggle
        [formControlName]="'value'"
        class="value boolValue child-value-component"
        [class.more-space]="moreSpace">
      </mat-slide-toggle>
      <span class="right-align" *ngIf="!boolValIsUnset && newResource && !valueRequiredValidator">
        <button mat-icon-button class="value-action cancel" title="cancel" (click)="boolValIsUnset = true">
          <mat-icon>cancel</mat-icon>
        </button>
      </span>
    </span>
    <mat-error
      *ngIf="
                valueFormControl.hasError('valueNotChanged') &&
                valueFormControl.dirty
            ">
      <span class="custom-error-message">New value must be different than the current value.</span>
    </mat-error>
    <app-comment-form
      [valueFormControlHasError]="hasError()"
      [(commentFormControl)]="commentFormControl"
      [valueFormControlValue]="valueFormControl.value.toString()"></app-comment-form>
  </span>
</ng-template>
