<div *ngIf="form" class="login-container">
  <!-- <h2 class="login-form-title mat-title">{{formLabel.title}}</h2> -->

  <!-- This is the login form -->
  <form [formGroup]="form" class="login-form">
    <!-- Username -->
    <mat-form-field class="full-width login-field">
      <mat-icon *ngIf="icons" matPrefix>person</mat-icon>

      <input #username [placeholder]="formLabel.name" autocomplete="username" formControlName="username" matInput />
    </mat-form-field>

    <!-- Password -->
    <mat-form-field class="full-width login-field">
      <mat-icon *ngIf="icons" matPrefix>lock</mat-icon>

      <input
        [placeholder]="formLabel.pw"
        autocomplete="current-password"
        formControlName="password"
        matInput
        type="password" />
    </mat-form-field>

    <br />

    <!-- Button: Login -->
    <button
      (click)="login()"
      [class.mat-primary]="!isError"
      [class.mat-warn]="isError"
      [disabled]="!form?.valid"
      class="full-width submit-button"
      mat-raised-button
      type="submit">
      <div class="progress-button-content">
        <dasch-swiss-app-progress-indicator *ngIf="loading" [color]="'white'" [status]="0" class="login-progress" />
        <mat-icon *ngIf="isError && !loading">close</mat-icon>
        <span>{{ isError ? formLabel.retry : formLabel.submit }}</span>
      </div>
    </button>

    <br /><br />

    <!-- Error message as an answer from knora api request -->
    <p *ngIf="loginFailed" class="login-error">{{ formLabel.error.failed }}</p>
  </form>
</div>
