<span *ngIf="mode === 'read'; else showForm" class="read-mode-view">
  <!-- only use linkify pipe if property's gui-element is not richtext; I had the same issue in regions -->
  <span class="rm-value text-value" [innerHtml]="valueFormControl.value | appLinkify"></span>
  <span class="rm-comment" *ngIf="shouldShowComment">{{ commentFormControl.value }}</span>
</span>
<ng-template #showForm>
  <span [formGroup]="form">
    <mat-form-field class="large-field child-value-component" floatLabel="never">
      <input
        *ngIf="!textArea"
        matInput
        [formControlName]="'value'"
        class="value"
        placeholder="Text value"
        type="text"
        [errorStateMatcher]="matcher" />
      <textarea
        *ngIf="textArea"
        matInput
        [formControlName]="'value'"
        class="value"
        placeholder="Text value"
        type="text"
        [errorStateMatcher]="matcher"
        rows="10"></textarea>

      <mat-error
        *ngIf="
                    valueFormControl.hasError('valueNotChanged') &&
                    (valueFormControl.touched || valueFormControl.dirty)
                ">
        <span class="custom-error-message">New value must be different than the current value.</span>
      </mat-error>
      <mat-error *ngIf="valueFormControl.hasError('required') && mode !== 'update'">
        A text value is <strong>required</strong>.
      </mat-error>
      <mat-error *ngIf="valueFormControl.hasError('required') && mode === 'update'">
        Empty values are not supported when updating a value. Consider using the delete button instead.
      </mat-error>
      <mat-error *ngIf="valueFormControl.hasError('duplicateValue')">
        <span class="custom-error-message"
          >This value already exists for this property. Duplicate values are not allowed.</span
        >
      </mat-error>
    </mat-form-field>
    <app-comment-form
      *ngIf="!commentDisabled && valueFormControl && commentFormControl"
      [valueFormControlHasError]="hasError()"
      [(commentFormControl)]="commentFormControl"
      [valueFormControlValue]="valueFormControl.value"></app-comment-form>
  </span>
</ng-template>
